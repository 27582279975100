type InputSubmitProps = {
  placeholder: string;
  submitLabel: string;
  buttonClasses?: string;
  onChangeValue: (val: string) => void;
  submitAction: () => void;
};

export const InputSubmit = ({
  placeholder,
  submitLabel,
  buttonClasses,
  onChangeValue,
  submitAction,
}: InputSubmitProps) => (
  <div className="flex flex-row mb-5">
    <input
      className="rounded-l-lg p-4 mr-0 border-t border-b border-l text-gray-800 border-gray-200 bg-white focus:outline-none focus:bg-opacity-70"
      placeholder={placeholder}
      onChange={(event) => onChangeValue(event.target.value)}
    />
    <button
      className={
        "px-8 rounded-r-lg bg-green-400 text-gray-800 font-bold p-4 w-36 uppercase border-white border-t border-b border-r focus:outline-none focus:bg-opacity-70 " +
        (buttonClasses ? buttonClasses : "")
      }
      onClick={submitAction}
    >
      {submitLabel}
    </button>
  </div>
);
