import React from "react";
import io from "socket.io-client";

const ENDPOINT = window.location.hostname.includes("localhost")
  ? "ws://localhost:1337"
  : (window.location.protocol.includes("https") ? "wss://" : "ws://") +
    window.location.hostname;

export const socket = io(ENDPOINT, {
  withCredentials: true,
  extraHeaders: {
    // "my-custom-header": "abcd",
  },
});
export const SocketContext = React.createContext(socket);
