import { useCallback, useContext, useEffect, useState } from "react";

import { SocketContext } from "./context/socket";

interface Player {
  name: string;
  id: string;
  isHost: boolean;
}

interface Round {
  roundState: string;
  currentPlayerId: string;
  currentPrompt: string;
  results: RoundResult;
  card: Card;
}

interface RoundResult {
  playerVotes: Record<string, number>;
  yVotes: number;
  nVotes: number;
}

interface Card {
  prompts: Prompt[];
}

interface Prompt {
  title: string;
  spicyness: number;
}

export interface GameState {
  gameState: string;
  players: Player[];
  round: Round;
  points: Record<string, any>;
}

function Game({
  gameState,
  playerId: currentPlayerId,
}: {
  gameState: GameState;
  playerId: string;
}) {
  const socket = useContext(SocketContext);

  const handleAlert = useCallback((data) => {
    console.log("ALERT", data);
    alert(data);
  }, []);
  const handleInfo = useCallback((data) => {
    alert(data);
  }, []);

  useEffect(() => {
    // as soon as the component is mounted, do the following tasks:

    // subscribe to socket events
    socket.on("alert", handleAlert);
    socket.on("info", handleInfo);

    socket.on("nextRound", () => {
      setChosenNumber(undefined);
      setChoseY(undefined);
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off("alert", handleAlert);
      socket.off("info", handleInfo);
    };
  });

  const startGame = () => {
    socket.emit("startGame");
  };
  const nextRound = () => {
    socket.emit("nextRound");
  };

  const choosePrompt = (i: number) => {
    socket.emit("choosePrompt", i);
  };

  const [choseY, setChoseY] = useState<boolean>();
  const [chosenNumber, setChosenNumber] = useState<number>();

  const choosePlayerNumber = (i: number) => {
    setChosenNumber(i);
    socket.emit("choosePlayerNumber", i);
  };

  const chooseSelf = (res: string) => {
    if (choseY === undefined) {
      setChoseY(res === "Y");
      socket.emit("chooseSelf", res);
    }
  };

  const renderGame = function (gameState: GameState) {
    const isHostPlayer = gameState.players.find(
      (player) => player.id === currentPlayerId
    )?.isHost
      ? true
      : false;

    switch (gameState.gameState) {
      case "LOBBY":
        return (
          <div className="flex flex-row justify-center items-center h-screen w-screen w-4/6">
            <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3 bg-white bg-opacity-50">
              <span className="font-bold">Players</span>
              <ul>
                {gameState.players.map(function (player: Player, i: number) {
                  return (
                    <li
                      key={player.id}
                      className={player.id === currentPlayerId ? "italic " : ""}
                    >
                      {player.name}
                      {player.isHost ? "👑" : ""}
                    </li>
                  );
                })}
              </ul>
              <br />
              <span className="font-bold">
                Options (soon<sup>TM</sup>)
              </span>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4"
                  disabled
                />
                <span className="ml-1">Shuffle prompts in cards</span>
              </label>
              <label className="inline-flex items-center">
                <input type="file" className="form-file w-24" disabled />
                <span className="ml-1">Upload card set</span>
              </label>
              <br />
              <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3">
                {isHostPlayer ? (
                  <button
                    className="rounded-lg bg-green-400 text-gray-800 font-bold p-4 w-auto uppercase focus:outline-none focus:bg-opacity-70"
                    onClick={startGame}
                  >
                    Start Game
                  </button>
                ) : (
                  <div className="rounded-lg bg-gray-400 text-gray-800 font-bold p-4 w-auto uppercase focus:outline-none focus:bg-opacity-70 text-center">
                    Start Game
                    <br />
                    <sup>Waiting for host</sup>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case "RUNNING":
        switch (gameState.round?.roundState) {
          case "PICK":
            return (
              <div className="flex flex-row justify-center overflow-auto items-center h-screen w-screen w-4/6">
                <div className="flex flex-col rounded-2xl h-auto p-3 bg-white bg-opacity-50">
                  {gameState.round.currentPlayerId === currentPlayerId ? (
                    <div>
                      <p className="font-bold text-center">Pick a prompt!</p>
                      <br />
                      <div className="flex flex-col space-y-5">
                        {gameState.round.card.prompts.map(function (
                          prompt: Prompt,
                          i: number
                        ) {
                          return (
                            <button
                              key={`p-${i}`}
                              className="rounded-lg bg-green-400 text-gray-800 font-bold p-4 w-auto uppercase focus:outline-none focus:bg-opacity-70"
                              onClick={() => choosePrompt(i)}
                            >
                              {prompt.title} {"🌶️".repeat(prompt.spicyness)}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>Someone is taking their time ...</div>
                      <div>
                        Blame{" "}
                        <b>
                          {
                            gameState.players.find(
                              (player) =>
                                player.id === gameState.round.currentPlayerId
                            )?.name
                          }
                        </b>
                        !
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          case "VOTE":
            return (
              <div>
                <div className="flex flex-col justify-center items-center h-screen w-screen space-y-5 w-4/6">
                  <div className="flex flex-col h-auto">
                    <div className="rounded-lg bg-blue-400 text-gray-800 text-center font-bold p-4 uppercase focus:outline-none focus:bg-opacity-70">
                      {gameState.round.currentPrompt}
                    </div>
                  </div>
                  <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3 bg-white bg-opacity-50 w-auto xl:w-3/6 text-center">
                    <div className="">
                      <p className="font-bold">
                        Choose whether this prompt applies to you!
                      </p>
                      <p>
                        <sup>First click counts!</sup>
                      </p>
                      <br />
                      <div
                        className={`pokerchip green content-after-Y cursor-pointer ${
                          choseY === true ? "border-yellow-400 border-2" : ""
                        }`}
                        onClick={() => chooseSelf("Y")}
                      />
                      <div
                        className={`pokerchip red content-after-N cursor-pointer ${
                          choseY === false ? "border-yellow-400 border-2" : ""
                        }`}
                        onClick={() => chooseSelf("N")}
                      />

                      <br />
                      <br />
                      <br />
                      <p className="font-bold">
                        How many other players does this apply to?
                      </p>
                      <p>
                        <sup>You may change your answer!</sup>
                      </p>
                      <div
                        key={`chip-0`}
                        className={`cursor-pointer pokerchip transform scale-75 white content-after-0 ${
                          chosenNumber === 0 ? "border-blue-400 border-2" : ""
                        }`}
                        onClick={() => choosePlayerNumber(0)}
                      />
                      {gameState.players.map(function (
                        player: Player,
                        i: number
                      ) {
                        return (
                          <div
                            key={`chip-${i}`}
                            className={
                              "cursor-pointer pokerchip transform scale-75 white content-after-" +
                              (i + 1) +
                              ` ${
                                chosenNumber === i + 1
                                  ? "border-blue-400 border-2"
                                  : ""
                              }`
                            }
                            onClick={() => choosePlayerNumber(i + 1)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          case "RESULTS":
            return (
              <div>
                <div className="flex flex-col justify-center items-center h-screen w-screen space-y-5 w-4/6">
                  <div className="flex flex-col h-auto">
                    <div className="rounded-lg bg-blue-400 text-gray-800 text-center font-bold p-4 uppercase focus:outline-none focus:bg-opacity-70">
                      {gameState.round.currentPrompt}
                    </div>
                  </div>
                  <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3 bg-white bg-opacity-50 w-auto xl:w-3/6 text-center">
                    <div className="">
                      <p className="font-bold">Results</p>
                      <br />
                      <div
                        className={
                          "pokerchip green content-after-" +
                          gameState.round.results.yVotes
                        }
                      />
                      <div
                        className={
                          "pokerchip red content-after-" +
                          gameState.round.results.nVotes
                        }
                      />
                      <br />
                      <hr />
                      <br />
                      <table className="text-left m-auto table-fixed">
                        <tbody>
                          <tr>
                            <td className="px-4 py-2">player</td>
                            <td className="px-4 py-2">answer</td>
                            <td className="px-4 py-2">points total</td>
                          </tr>
                          {Object.keys(gameState.round.results.playerVotes).map(
                            function (playerId, i) {
                              return (
                                <tr key={i}>
                                  <td>
                                    {
                                      gameState.players.find(
                                        (player: Player) =>
                                          player.id === playerId
                                      )?.name
                                    }
                                  </td>
                                  <td className="text-center">
                                    <b>
                                      {
                                        gameState.round.results.playerVotes[
                                          playerId
                                        ]
                                      }
                                    </b>
                                  </td>
                                  <td className="text-center">
                                    (
                                    {gameState.points
                                      ? gameState.points[playerId]
                                      : ""}
                                    )
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      <br />
                      <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3">
                        {isHostPlayer ? (
                          <button
                            className="relative rounded-lg bg-green-400 text-gray-800 font-bold p-4 w-auto uppercase focus:outline-none focus:bg-opacity-70"
                            onClick={nextRound}
                          >
                            Next Round
                          </button>
                        ) : (
                          <div className="relative rounded-lg bg-gray-400 text-gray-800 font-bold p-4 w-auto uppercase focus:outline-none focus:bg-opacity-70">
                            Next Round
                            <br />
                            <sup>Waiting for host</sup>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          default:
            return <div></div>;
        }
      default:
        return <div></div>;
    }
  };

  return <div>{renderGame(gameState)}</div>;
}

export default Game;
