import React from "react";
import ReactDOM from "react-dom";
import { SocketContext, socket } from "./context/socket";
import App from "./App";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <SocketContext.Provider value={socket}>
      <div className="fixed inset-0 bg-masks bg-cover">
        <div className="fixed inset-0 bg-black bg-opacity-50">
          <App />
        </div>
      </div>
    </SocketContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
