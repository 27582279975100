import { InputSubmit } from "./components/InputSubmit";

import "./App.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { SocketContext } from "./context/socket";

import Game, { GameState } from "./Game";

function App() {
  const [joinCode, setJoinCode] = useState("");
  const [createCode, setCreateCode] = useState("");
  const [playerName, setPlayerName] = useState("");

  const createGame = () => {
    window.localStorage.clear();
    socket.emit("createGame", createCode);
  };
  const joinGame = () => {
    if (window.localStorage.getItem("playerId")) {
      socket.emit("rejoinGame", {
        code: joinCode,
        playerId: window.localStorage.getItem("playerId"),
      });
      window.localStorage.clear();
    } else {
      window.localStorage.clear();
      socket.emit("joinGame", joinCode);
    }
  };
  const changePlayerName = () => {
    socket.emit("setPlayerName", playerName);
  };

  const [isConnected, setConnected] = useState(false);
  const [completeGameState, setGameState] = useState<GameState>();
  const [playerId, setPlayerId] = useState();

  const socket = useContext(SocketContext);

  const handleJoinedGame = useCallback((data) => {
    setConnected(true);
    setPlayerId(data);
    window.localStorage.setItem("playerId", data);
  }, []);

  const handleAlert = useCallback((data) => {
    alert(data);
  }, []);

  useEffect(() => {
    // subscribe to socket events
    socket.on("joinedGame", handleJoinedGame);
    socket.on("updateGame", (data) => setGameState(data));
    socket.on("alert", handleAlert);

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off("joinedGame", handleJoinedGame);
      socket.off("updateGame");
      socket.off("alert", handleAlert);
    };
  });

  return (
    <div>
      {!isConnected ? (
        <div className="flex flex-col justify-center items-center h-screen w-screen space-y-10 w-auto">
          <div className="h-10 text-white text-center">
            <h1 className="text-4xl">Secret Guess.r</h1>
          </div>
          <div className="flex flex-col rounded-2xl overflow-auto h-auto p-3 bg-white bg-opacity-40">
            <InputSubmit
              placeholder="enter code"
              submitLabel="JOIN"
              onChangeValue={setJoinCode}
              submitAction={joinGame}
            />
            <InputSubmit
              placeholder="enter code"
              submitLabel="CREATE"
              buttonClasses="bg-blue-400"
              onChangeValue={setCreateCode}
              submitAction={createGame}
            />
          </div>
          <div className="w-64 h-32 relative text-center">
            <svg
              className="h-8 w-8 text-white m-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-white">
              ...
              <br />
              Rejoin a running game by using the same code again.
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span className="flex flex-col justify-center items-center rounded-l-lg p-2 mr-0 absolute top-0 right-0 bg-white bg-opacity-40 pl-5 pr-5 rounded-2xl">
            <p className="text-xs">PlayerId: {playerId}</p>
            {playerId &&
            (!completeGameState || completeGameState.gameState === "LOBBY") ? (
              <div>
                <br />
                <InputSubmit
                  placeholder="enter username"
                  submitLabel="SUBMIT"
                  buttonClasses="bg-grey-400"
                  onChangeValue={setPlayerName}
                  submitAction={changePlayerName}
                />
              </div>
            ) : (
              <span></span>
            )}
          </span>
          {completeGameState && playerId ? (
            <Game gameState={completeGameState!} playerId={playerId!} />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
